import React from 'react';
import {ListPagination} from "./ListPagination";

const LIST_MAX_COUNT = 20;

export default class UserManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfos: [],
      pageNum: 1,
      pageCount: 0
    };
  }

  tsToLocalTimeStr = (msTs) => {
    let localDate = new Date((msTs + 32400) * 1000).toISOString();
    return localDate.replace('T', ' ').substr(0, 19);
  };

  componentDidMount() {
    fetch('/api/users').then(res => res.json()).then(res => {
      let arrUInfos = res;
      // for (let i = 0; i < 500; i++) {
      //   arrUInfos = arrUInfos.concat(JSON.parse(JSON.stringify(res)));
      // }
      // arrUInfos.forEach((v, i) => v.nick_name = `nick${i}`);
      this.setState({
        userInfos: arrUInfos,
        pageNum: 1,
        pageCount: Math.floor((arrUInfos.length + LIST_MAX_COUNT - 1) / LIST_MAX_COUNT)
      });
    });
  }

  handlePageClick = (pageNum) => this.setState({pageNum: pageNum});

  render() {
    const {userInfos} = this.state;
    console.log(userInfos);
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="page-header">FineCam Cloud <small>유저 정보 관리</small></h2>
          </div>
        </div>
        <div className="card">
          <h5 className="card-header bg-dark text-white">유저 목록</h5>
          <div className="card-body">
            <table className="table" style={{textAlign: "center"}}>
              <thead>
              <tr>
                <th>id</th>
                <th>이름</th>
                <th>유형</th>
                <th>별명</th>
                <th>등록일</th>
                <th>갱신일</th>
              </tr>
              </thead>
              <tbody>
              {
                userInfos.map((v, i) => (
                  Math.floor((i + LIST_MAX_COUNT) / LIST_MAX_COUNT) === this.state.pageNum ? (
                    <tr key={i}>
                      <td className="uid-style">{v.uid}</td>
                      <td>{v.username || v.mobile || v.email || v.open_id}</td>
                      <td>{v.mobile ? "mobile" : v.email ? "email" : "open_id"}</td>
                      <td>{v.nick_name || '-'}</td>
                      <td>{this.tsToLocalTimeStr(v.create_time)}</td>
                      <td>{this.tsToLocalTimeStr(v.update_time)}</td>
                    </tr>
                  ) : null)
                )
              }
              </tbody>
            </table>
            <ListPagination pageNum={this.state.pageNum} pageCount={this.state.pageCount}
                            onPageClick={this.handlePageClick}/>
          </div>
        </div>
      </div>
    );
  }
}
