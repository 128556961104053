import React, {Component, useEffect} from 'react';
import {Redirect} from 'react-router';
import {HashRouter, Route, Switch} from 'react-router-dom';
import ReactNavBar from './ReactNavBar';

import './App.css';
import DeviceManager from "./DeviceManager";
import UserManager from "./UserManager";

const GotoAdminHome = () => {
  useEffect(() => document.location.href = 'http://admin.fine-drive.com');
  return (<Redirect to="/" />);
};

class App extends Component {
  state = {
    response: '',
    post: '',
    responseToPost: '',
    navBar: {
      authUserId: 'taiuk',
      ezlogServer: '127.0.0.1',
      helpDocuments: [],
      sideMenus: [
        {href: "/#/users", icon: "fa-list-alt", name: "유저 정보 관리"},
        {href: "/#/devices", icon: "fa-list-alt", name: "기기 정보 관리"}
      ]
    }
  };

  componentDidMount() {
    this.callApi()
      .then(res => this.setState({response: res.express}))
      .catch(err => console.log(err));
  }

  callApi = async () => {
    const response = await fetch('/api/hello');
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);

    return body;
  };

  handleSubmit = async e => {
    e.preventDefault();
    const response = await fetch('/api/world', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({post: this.state.post}),
    });
    const body = await response.text();

    this.setState({responseToPost: body});
  };

  render() {
    return (
      <div>
        <ReactNavBar navBar={this.state.navBar} onSelectedMenu={this.onSelectedMenu}/>
        <div id="wrapper">
          <div id="page-wrapper">
            <HashRouter>
              <Switch>
                <Route path="/" exact component={UserManager} />
                <Route path="/home" component={GotoAdminHome}/>
                <Route path="/users" component={UserManager} />
                <Route path="/devices" component={DeviceManager} />
              </Switch>
            </HashRouter>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
