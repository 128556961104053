import React from "react";

export const ListPagination = ({pageNum, pageCount, onPageClick = () => console.log('onClick SKIP')}) => {
  let pageNums = Array(pageCount).fill(0).map((v, i) => i + 1);
  const onPrevNextPageClick = (e) => {
    // console.log(e.target.innerText, e.target.classList.contains("disabled"));
    if (!e.target.classList.contains("disabled")) {
      pageNum = pageNum + ((e.target.innerText === "Prev") ? -1 : 1);
      if (pageNum < 1) pageNum = 1;
      if (pageNum > pageCount) pageNum = pageCount;
      onPageClick(pageNum);
    }
  };
  let firstPage = [pageNum - 7].map(v => v < 1 ? 1 : v)[0];
  let lastPage = [firstPage + 14].map(v => v > pageCount ? pageCount : v)[0];
  pageNums = pageNums.splice(lastPage - 15, 15);
  // console.log(`${pageNum}/${pageCount}`, pageNums);
  return (
    <ul className="pagination">
      <li className={"noselect btn btn-outline-dark " + (pageNums[0] > 1 ? "" : "disabled")}
          onClick={onPrevNextPageClick}>
        <div className="button">Prev</div>
      </li>
      {
        pageNums.map((v, i) => (
          <li className={"noselect  btn btn-outline-dark  " + (pageNum === v ? "active" : "")} key={i}
              style={{width: "36px"}} onClick={(e) => onPageClick(Number(e.target.innerText))}>{v}</li>
        ))
      }
      <li className={"noselect  btn btn-outline-dark  " + (pageNums.slice(-1)[0] < pageCount ? "" : "disabled")}
          onClick={onPrevNextPageClick}>Next
      </li>
    </ul>
  );
};
