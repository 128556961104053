import React from 'react';
import {ListPagination} from "./ListPagination";

const LIST_MAX_COUNT = 20;

export default class DeviceManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceInfos: [],
      pageNum: 1,
      pageCount: 0
    };
  }

  tsToLocalTimeStr = (msTs) => {
    let localDate = new Date((msTs + 32400) * 1000).toISOString();
    return localDate.replace('T', ' ').substr(0, 19);
  };

  componentDidMount() {
    fetch('/api/devices').then(res => res.json()).then(res => {
      let arrDevInfos = res;
      // for (let i = 0; i < 500; i++) {
      //   arrDevInfos = arrDevInfos.concat(JSON.parse(JSON.stringify(res)));
      // }
      this.setState({
        deviceInfos: arrDevInfos,
        pageNum: 1,
        pageCount: Math.floor((arrDevInfos.length + LIST_MAX_COUNT - 1) / LIST_MAX_COUNT)
      });
    });
  }

  handlePageClick = (pageNum) => this.setState({pageNum: pageNum});

  render() {
    const {deviceInfos} = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <h2 className="page-header">FineCam Cloud <small>기기 정보 관리</small></h2>
          </div>
        </div>
        <div className="card">
          <h5 className="card-header bg-dark text-white">기기 목록</h5>
          <div className="card-body">
            <table className="table" style={{textAlign: "center"}}>
              <thead>
              <tr>
                <th></th>
                <th>id</th>
                <th>제품명</th>
                <th>이름</th>
                <th>온라인</th>
                <th>등록일</th>
                <th>갱신일</th>
              </tr>
              </thead>
              <tbody>
              {
                deviceInfos.map((v, i) => (
                  Math.floor((i + LIST_MAX_COUNT) / LIST_MAX_COUNT) === this.state.pageNum ? (
                    <tr key={i}>
                      <td style={{padding: 1}}><img src={`https://images.tuyacn.com/${v.icon}`} width="32px"
                                                    height="32px"/></td>
                      <td className="uid-style">{v.id}</td>
                      <td>{v.product_name}</td>
                      <td>{v.name}</td>
                      <td>{v.online ? 'O' : 'X'}</td>
                      <td>{this.tsToLocalTimeStr(v.create_time)}</td>
                      <td>{this.tsToLocalTimeStr(v.update_time)}</td>
                    </tr>
                  ) : null)
                )
              }
              </tbody>
            </table>
            <ListPagination pageNum={this.state.pageNum} pageCount={this.state.pageCount}
                            onPageClick={this.handlePageClick}/>
          </div>
        </div>
      </div>
    );
  }
}
