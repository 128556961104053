import React from 'react';
import axios from 'axios';
import {Navbar} from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";

const Base64 = {
  _keyStr: "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",

  encode: function (input) {
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;

    while (i < input.length) {
      chr1 = input.charCodeAt(i++);
      chr2 = input.charCodeAt(i++);
      chr3 = input.charCodeAt(i++);

      enc1 = chr1 >> 2;
      enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
      enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
      enc4 = chr3 & 63;

      if (isNaN(chr2)) {
        enc3 = enc4 = 64;
      }
      else if (isNaN(chr3)) {
        enc4 = 64;
      }
      output = output + this._keyStr.charAt(enc1)
        + this._keyStr.charAt(enc2) + this._keyStr.charAt(enc3)
        + this._keyStr.charAt(enc4);
    }
    return output;
  }
};

export default class ReactNavBar extends React.Component {
  constructor(props) {
    super(props);
    console.log('constructor: ', props)
    this.state = {
      reqServletUrlPath: '/',
      topMainNavBarTitle: 'FineDigital Admin'
    };
  }

  render() {
    return (
      <Navbar variant="dark" style={{backgroundColor: "#222", borderBottom: "1px solid black"}}>
        <Navbar.Brand href="#home" style={{padding: "3px"}}>
          FineDigital Admin
        </Navbar.Brand>
        <div className="d-flex text-white" style={{position: "absolute", right: "0px"}}>
          <i className="fa fa-user" style={{position: "absolute", top: "50%", transform: "translateY(-50%)"}}/>
             <b className="caret"/>
          <NavDropdown alignRight title={this.props.navBar.authUserId} id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
            <NavDropdown.Divider/>
            <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
          </NavDropdown>
        </div>

        <div className="side-nav">
        <Nav defaultActiveKey="/home" className="flex-column">
          {
            this.props.navBar.sideMenus.map((menu, i) => (
              <Nav.Link key={i} href={menu.href} style={{padding: "15px"}}>
                <i className={`fa fa-fw ${menu.icon}`}/>&nbsp;{menu.name}
              </Nav.Link>
            ))
          }
        </Nav>
        </div>
      </Navbar>
    );
  }

  btnChangePasswordClick = function (e) {
    const password = prompt('Please enter new password', '');
    if (password != null && password.length > 0) {
      let data = {"UserId": e.target.dataset.uid, "UserPwd": password};
      let config = {
        headers: {'Content-Type': 'application/json;charset=UTF-8'}
      };
      axios.post('/change-password', data, config)
        .then((data) => {
          console.log(data);
          if (data.data.code === "000") {
            window.location = this.state.reqServletUrlPath;
          }
          else {
            alert("비밀번호 변경에 실패하였습니다. (관리자에게 문의 바랍니다.)");
          }
        })
        .catch(err => {
          console.log("AXIOS ERROR: ", err)
        });
    }
  };

  btnUserLogoutClick = function () {
    const Basic = 'Basic ' + Base64.encode('Anonymous:0000');
    axios.get("/logout", {}, {headers: {'Authorization': Basic}})
      .then((res) => window.location = "/")
      .catch((err) => window.location = "/");
  }
}
